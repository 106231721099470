@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@media screen and (min-width: 1032px) {
		h1 {
			font-size: 48px;
			font-weight: 800;
			line-height: 56px;
		}

		h2 {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			text-transform: uppercase;
		}

		h3 {
			font-size: 40px;
			font-weight: 800;
			line-height: 48px;
			letter-spacing: -2px;
		}
	}

	@media screen and (min-width: 820px) and (max-width: 1032px) {
		h1 {
			font-size: 40px;
			font-weight: 800;
			line-height: 48px;
		}

		h2 {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			text-transform: uppercase;
		}

		h3 {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
			letter-spacing: -1.6px;
		}
	}

	@media screen and (max-width: 819px) {
		h1 {
			font-size: 32px;
			font-weight: 800;
			line-height: 40px;
		}

		h2 {
			font-size: 24px;
			font-weight: 800;
			line-height: 32px;
			text-transform: uppercase;
		}

		h3 {
			font-size: 24px;
			font-weight: 800;
			line-height: 32px;
			letter-spacing: -1.2px;
		}
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	body,
	pre {
		@apply font-sans;
	}
}

@layer components {
	.word_break {
		@apply hyphens-auto;
		word-break: break-word;
	}

	.uppercase_title_large {
		@apply text-2xl font-extrabold uppercase;
	}

	.uppercase_title_medium {
		@apply text-base font-extrabold uppercase;
	}

	.xxlHeaderTitle {
		@apply font-extrabold leading-none sm:text-[88px] md:text-[136px] lg:text-[160px];
	}

	.super_title {
		@apply lg:text-4xlarge md:text-3xlarge sm:text-large font-extrabold tracking-tighter;
	}

	.title_large {
		@apply lg:text-2xlarge md:text-xlarge sm:text-large font-extrabold;
	}

	.title_medium {
		@apply lg:text-xlarge md:text-large font-extrabold sm:text-2xl;
	}

	.title {
		@apply lg:text-large font-extrabold sm:text-2xl md:text-2xl;
	}

	.subtitle {
		@apply text-2xl font-normal tracking-[-1.2px];
	}

	.subtitle_highlight {
		@apply text-2xl font-extrabold tracking-[-1.2px];
	}

	.p_highlight {
		@apply text-base font-extrabold;
	}

	.micro {
		@apply text-xsmall font-normal;
	}

	.micro_highlight {
		@apply text-xsmall font-extrabold;
	}
}
