/* We need to add styles this way because it behaves inconsistently with inline Tailwind classes */

.list {
	@apply my-2 w-full pl-5 [&_p]:mb-0;
}

.unorderedListItem li {
	@apply list-disc;
}

.orderedListItem li {
	@apply list-decimal;
}

.link {
	@apply font-extrabold underline decoration-solid;
}
