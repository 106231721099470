:root {
	--gray-05: #f6f6f6;
	--gray-10: #ededed;
	--gray-25: #d0d0d0;
	--gray-40: #b2b2b2;
	--gray-55: #929292;
	--gray-70: #6f6f6f;
	--gray-85: #4a4a4a;
	--gray-90: #3c3c3c;

	--white: #ffffff;
	--black: #1a1919;
	--black-rgb: 26, 25, 25;

	--text-primary: #3c3c3c;
	--text-primary-rgb: 60, 60, 60;

	--primary: #fa5952;
	--primary-rgb: 250, 89, 82;
	--primary-hover: #f9746f;
	--primary-pressed: #d83b35;

	--secondary: #be242e;

	--accent-orange: var(--primary);
	--accent-blue: #515d82;
	--accent-blue-rgb: 54, 78, 114;
	--accent-dark-blue: #1b1f2d;
	--accent-beige: #c2ae9e;
	--accent-beige-rgb: 194, 174, 158;
	--accent-dark-beige: #92735b;
	--accent-gray: #fafafa;

	--status-correct: #1bbb6e;
	--status-warning: #fdc022;
	--status-error: #f92920;

	/* CV Maker */
	--bs-body-font-family: var(--base-font-family) !important;
	--bs-body-bg: transparent !important;

	--default-ring: #2563eb;
}

[data-theme='dark'] {
	--primary: #000000;
	--primary-2: #111;
	--secondary: #ffffff;
	--secondary-2: #f1f3f5;
	--hover: rgba(255, 255, 255, 0.075);
	--hover-1: rgba(255, 255, 255, 0.15);
	--hover-2: rgba(255, 255, 255, 0.25);
	--selection: var(--purple);

	--text-base: white;
	--text-primary: white;
	--text-secondary: black;

	--accent-9: #fff;
	--accent-8: #fafafa;
	--accent-7: #eaeaea;
	--accent-6: #999999;
	--accent-5: #888888;
	--accent-4: #666666;
	--accent-3: #444444;
	--accent-2: #333333;
	--accent-1: #111111;
	--accent-0: #000;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	height: 100%;
	box-sizing: border-box;
	touch-action: manipulation;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--body);
	color: var(--text-primary);
}

body {
	position: relative;
	min-height: 100%;
	margin: 0;
}

a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

b,
strong {
	font-weight: 800 !important;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

.section {
	@apply sm:mb-[72px] md:mb-28 lg:mb-[152px];
}

.section:last-child {
	@apply mb-0;
}

.section .section {
	@apply my-0 pb-0;
}

.custom-container .custom-container:not(.nested-container) {
	@apply max-w-none p-0;
}

.grecaptcha-badge {
	@apply invisible;
}

.pageSection {
	@apply mb-12;
}

.pageSection .section {
	@apply mb-6;
}

a span.subtitleText span,
a span.legalText span {
	@apply text-primary no-underline;
}

input[type='number'],
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-moz-appearance: textfield;
	-webkit-appearance: none;
	margin: 0;
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.embeddedServiceHelpButton .helpButton .uiButton {
	background-color: #005290;
	font-family: 'Arial', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
	outline: 1px solid #005290;
}

/* Hungarian chatbot */
#webchat * {
	font-family: inherit !important;
}

#webchat .launcher {
	display: none !important;
}
